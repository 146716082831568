<script lang='ts'>
  import { TestQuestion } from '@/definitions/langoid'

  export let question: TestQuestion
  export let elements: HTMLInputElement[] = []
  export let id = 0
  export let checkSolution = () => { }
</script>
<div class='radio-answers'>
  {#if question}
    {#each question.question.filter((item, i) => question.question.indexOf(item) === i) as answer, index}
      <label class='singleAnswer'>
        <input
          type='radio'
          value={answer}
          bind:group={elements[id]}
          on:change={() => checkSolution()}
          name='answer_{question.id}'
        />
        <span><em>{1 + index} </em>{answer}</span>
      </label>
    {/each}
  {/if}
</div>
<style lang='scss'>
  .radio-answers {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    font-size: 2rem;

    > .singleAnswer {
      > input[type='radio'] {
        display: none;
        opacity: 0;

        + span {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 6rem;
          padding: 1rem 2rem;
          color: var(--text-primary-color);
          background: var(--secondary-background);
          border-radius: 0.6rem;
          box-shadow: 0 0.1rem 0.3rem var(--Gray-Medium);
        }
      }

      &:hover > input[type='radio'] + span {
        outline: solid var(--Primary-Medium) 0.2rem;
        cursor: pointer;
      }

      > input[type='radio']:active + span,
      > input[type='radio']:checked + span,
      > input[type='radio']:focus + span {
        outline: solid var(--Primary-Medium) 0.4rem;
        cursor: pointer;
      }

      > span > em {
        position: absolute;
        right: 0.5rem;
        bottom: 0.2rem;
        font: var(--Regular-200);
        color: var(--text-primary-color);
      }
    }
  }

  @media (max-width: 768px) {
    .radio-answers {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.2rem;
      font-size: 1.6rem;

      > .singleAnswer > input[type='radio'] + span {
        min-height: 5rem;
      }
    }
  }
</style>
