<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'

  import { TestAlert } from '@/definitions/test/api'

  export let alerts: TestAlert[] = [],
    batchType: string = 'words'

  const testType: string = ['words', 'customWords'].includes(batchType) ? $_('alert.word') : $_('alert.sentence')

</script>
<div class='_vertical-small'>
  {#each alerts as alert}
    <div class='alert-block -{alert.type}'>
      <Icon icon='WarningCircle' weight='fill' />
      {#if alert.message === 'tooManyBatchLoops'}
        {$_('alert.tooManyBatchLoops')} {alert.value}
      {:else if alert.message === 'badPerformancePenalty'}
        {$_('alert.badPerformancePenalty')}
      {:else if alert.message === 'insane'}
        {$_('alert.insane', { values: { testType } })}
      {:else}
        {alert.message}
      {/if}
    </div>
  {/each}
</div>
<style lang='scss'>
  .alert-block {
    display: flex;
    gap: 0.8rem;
    width: 100%;
    padding: 0.8rem 1.2rem;
    background-color: var(--Gray-Lighter);
    border-radius: 0.8rem;

    &.-success {
      color: var(--success-text);
      background: var(--success-background);
      border: 0.1rem solid var(--success-text);
    }

    &.-error {
      color: var(--error-text);
      background-color: var(--error-background);
      border: 0.1rem solid var(--error-text);

    }

    &.-warning {
      color: var(--Yellow-Medium);
      background: var(--Warning-Lighter);
      border: 0.1rem solid var(--Yellow-Medium);
    }
  }
</style>
