<script lang='ts'>
  import { RATING } from '@/config/constants'

  import { _ } from '@/libs/i18n'

  export let mode: 'basic' | 'advanced' | 'expert' = 'basic',
    isAnswerSaved = false,
    setRating = (rating: number, session: 'initial' | 'repeat') => { },
    iDontKnow = () => { },
    session: 'initial' | 'repeat' = 'initial'
</script>
{#if ['expert', 'advanced'].includes(mode)}
  <div class='buttons-wrapper'>
    {#if mode === 'expert'}
      <button class='control -rate1' disabled={isAnswerSaved} type='button' on:click={() => setRating(RATING.WRONG_BUT_REMEMBER_CORRECT, session)}>1</button>
      <button class='control -rate2' disabled={isAnswerSaved} type='button' on:click={() => setRating(RATING.WRONG_BUT_LOOKED_EASY, session)}>2</button>
      <button class='control -rate3' disabled={isAnswerSaved} type='button' on:click={() => setRating(RATING.CORRECT_BUT_VERY_HARD, session)}>3</button>
      <button class='control -rate4' disabled={isAnswerSaved} type='button' on:click={() => setRating(RATING.CORRECT_AFTER_THINKING, session)}>4</button>
      <button class='control -rate5' disabled={isAnswerSaved} type='button' on:click={() => setRating(RATING.CORRECT_EASY_AND_FAST, session)}>5</button>
    {:else if mode === 'advanced'}
      <button class='control -rate1' disabled={isAnswerSaved} title='1' type='button' on:click={() => setRating(RATING.WRONG_BUT_REMEMBER_CORRECT, session)}>
        Don't know
      </button>
      <button class='control -rate3' disabled={isAnswerSaved} title='3' type='button' on:click={() => setRating(RATING.CORRECT_BUT_VERY_HARD, session)}>Hard
      </button>
      <button class='control -rate5' disabled={isAnswerSaved} title='5' type='button' on:click={() => setRating(RATING.CORRECT_EASY_AND_FAST, session)}>Easy
      </button>
    {/if}
  </div>
{:else}
  {#if session === 'initial'}
    <button
      class='boki'
      title='CTRL + 0'
      type='button'
      on:click={iDontKnow}
    >{$_('testsPage.iDontKnow')}</button>
  {/if}
{/if}
<style lang='scss'>
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
</style>
