<script>
  import { TESTS } from '@/config/constants'

  import { _ } from '@/libs/i18n'

  export let type,
    answerStatusClass
</script>

{#if answerStatusClass === ''}
  <h2 class='question-title'>
    {#if type === TESTS.RADIO} {$_('testsPage.chooseTranslation')}
    {:else if type === TESTS.TRANSLATE_AUDIO} {$_('testsPage.writeWordFromAudio')} <br />
      <small>{$_('testsPage.answerInInterfaceLanguage')}</small>
    {:else if type === TESTS.TRANSLATE_WRITTEN} {$_('testsPage.translateWord')} <br />
      <small>{$_('testsPage.answerInLearningLanguage')}</small>
    {:else if type === TESTS.TRANSLATE_SENTENCE} {$_('testsPage.translateSentence')}
    {:else if type === TESTS.AUDIO_SENTENCE} {$_('testsPage.writeSentenceFromAudio')}
    {:else if type === TESTS.CUSTOM_WORD}
      {$_('testsPage.translateWord')}
    {:else if type === TESTS.CUSTOM_SENTENCE}
      {$_('testsPage.translateSentence')}
    {/if}
  </h2>
  <hr />
{/if}

<style lang='scss'>
  .question-title {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font: var(--Semibold-600);

    > small {
      font: var(--Regular-400);
      color: var(--text-primary-color);
    }
  }

  @media(max-width: 768px) {
    .question-title {
      > small {
        font: var(--Regular-400);
      }
    }
  }
</style>
